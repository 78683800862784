$office-cubicles-primary: #b91c45;
$office-cubicles-secondary: #444b53;
$office-cubicles-progress: #e489a2;
$office-cubicles-hover: #E0E0E1;

:export {
    primary: $office-cubicles-primary;
    secondary: $office-cubicles-secondary;
    progress: $office-cubicles-progress;
    hover: $office-cubicles-hover;
}
